<template>
  <div :style="`height: ${height}px`" id="list">
    <div class="container">
      <div class="nav">
        <div>
          <span>专区内加购可用机会</span>
          <span style="font-size: 24px;margin-top: 3px">{{ unuseCount }}次</span>
        </div>
        <div class="wallet_box_haved">
          <span>已使用机会</span>
          <span style="font-size: 24px;margin-top: 3px">{{ useCount }}次</span>
        </div>
        <div class="line"></div>
      </div>

      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div v-for="(item,index) in datalist" :key="index" class="list_item">
          <div class="list_item_top">
            <div class="list_item_num">{{ brandId=== 4?'大健康':'美妆' }}采购订单【{{item.orderNums}}】</div>
            <div class="list_item_price">+{{item.count}}次</div>
          </div>
          <div class="list_item_time">
            <div>{{item.startTime}}</div>
            <div v-if="item.endTime">{{item.endTime}}到期</div>
          </div>
          <img v-if="item.state == 2" style="position: absolute;right: 0;bottom: 0;width: 60px;height: 50px" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210508112957930.png" alt="">
          <img v-if="item.state == 3" style="position: absolute;right: 0;bottom: 0;width: 60px;height: 50px" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210508113157212.png" alt="">
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecialRecordList",
  data() {
    return {
      brandId: 3,
      height: '',
      loading: false,
      finished: false,
      ps: 10,
      pg: 0,
      datalist: [],
      useCount: 0,
      unuseCount: 0
    }
  },
  created() {
    let { brandId } = this.$route.query;
    this.height = document.documentElement.clientHeight;
    if (brandId) this.brandId = +brandId;
  },
  methods: {
    onLoad() {
      this.pg++;
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.get("/OrderGoods/PreferentialZone/GetGenerallotteryList", {
        brandId: this.brandId,
        start: this.pg, length: this.ps
      }, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let { useCount, unuseCount, pzList } = res.response;
          if (this.pg === 1) {
            this.useCount = useCount;
            this.unuseCount = unuseCount;
          }
          if (pzList.length < this.ps) this.finished = true;
          this.datalist = this.datalist.concat(pzList);
        } else {
          this.$toast(res.msg);
        }
        this.loading = false
      });
    },

  }
}
</script>

<style lang="scss" scoped>
#list {
  background-color: #d1c1ff;
  overflow: hidden;

  .container {
    background-color: #fff;
    width: calc(100% - 30px);
    height: calc(100% - 20px);
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px 10px 0 0;
    overflow-y: scroll;
    position: relative;
    padding-top: 80px;
    /*padding-bottom: 40px;*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    .nav {
      position: fixed;
      width: calc(100% - 30px);
      top: 20px;
      border-radius: 10px 10px 0 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 15px 50px;
      box-sizing: border-box;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fff;
      z-index: 1;
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fe9af0;
      }
      .line {
        height: 40px;
        width: 2px;
        background-color: #f1f1f1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    .van-list {
      padding-bottom: 100px;
    }

    .list_item {
      margin-top: 15px;
      margin-left: 15px;
      padding-bottom: 15px;
      position: relative;
      border-bottom: 1px solid #f1f1f1;

      .list_item_top {
        display: flex;
        justify-content: space-between;

        .list_item_num {
          word-break: break-all;
        }

        .list_item_price {
          color: #fe9af0;
          font-size: 12px;
          font-weight: bold;
          margin-right: 5px;

          &.font-green {
            color: #000;
          }
        }
      }

      .list_item_time {
        margin-top: 10px;
        align-items: center;
        display: flex;

        div {
          color: #999999;
          margin-left: 5px;

          &:nth-child(1) {
            margin-right: 10px;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
}
</style>